import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import queryString from 'query-string'
import loadable from '@loadable/component'

import { Container } from 'src/components/UI/Grid/Grid'
import ProductListing from 'src/components/ProductListing/ProductListing'
import ArticleGrid from 'src/components/ArticleGrid/ArticleGrid'

import * as styles from './search.module.scss'
const ProductsContainer = loadable(
  () => import('../../components/ProductsContainer/ProductsContainer')
)

const SearchPage = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [searchWord, setSearchWord] = useState('')

  useEffect(() => {
    const hash = queryString.parse(window.location.hash)
    if (hash && hash.search && typeof hash.search === 'string') {
      if (hash.search !== searchWord) {
        setSearchWord(hash.search)
      }
    }
  })

  return (
    <ProductsContainer
      productIds={[]}
      products={[]}
      isSearchPage={true}
      searchWord={searchWord}
      render={(data) => {
        let gotPosts = false
        let countriesAndRegions = [...data.countries, ...data.regions]
        if (
          data.articles.length > 0 ||
          data.producers.length > 0 ||
          countriesAndRegions.length > 0
        ) {
          gotPosts = true
        }
        return (
          <Flex flexDirection="column" width={1} className={styles.wrapper}>
            <Box className={styles.hero}>
              <Container>
                <Flex
                  width={1}
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  paddingTop={[9, null, null, 15]}
                  flexDirection="column"
                >
                  <Box
                    className={styles.label}
                    marginBottom={[2, null, null, 3]}
                    as="h1"
                  >
                    Sökresultat för
                  </Box>
                  <Box
                    marginBottom={[6, null, null, 14]}
                    className={styles.searchWord}
                    as="p"
                  >{`“${searchWord}”`}</Box>
                  {data.noHits && (
                    <Box
                      className={styles.noResult}
                      as="p"
                      paddingBottom={[5, null, null, 9]}
                    >
                      {`Vi hittade tyvärr inga resulat för ${searchWord} men du kanske gillar något annat i vårt sortiment:`}
                    </Box>
                  )}
                  <Flex
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="center"
                    paddingBottom={[5, null, null, 9]}
                  >
                    <Box
                      marginX={[3]}
                      marginBottom={[2, null, null, 0]}
                      className={[
                        styles.catButton,
                        activeTab === 0 ? styles.active : '',
                      ].join(' ')}
                      onClick={() => {
                        if (activeTab !== 0) {
                          setActiveTab(0)
                        }
                      }}
                      as="button"
                    >{`Produkter (${data.noHits ? 0 : data.totalHits})`}</Box>
                    <Box
                      marginX={[3]}
                      marginBottom={[2, null, null, 0]}
                      className={[
                        styles.catButton,
                        activeTab === 1 ? styles.active : '',
                      ].join(' ')}
                      disabled={data.articles.length < 1}
                      onClick={() => {
                        if (activeTab !== 1 && data.articles.length > 0) {
                          setActiveTab(1)
                        }
                      }}
                      as="button"
                    >{`Vinspiration (${data.articles.length})`}</Box>
                    <Box
                      marginX={[3]}
                      className={[
                        styles.catButton,
                        activeTab === 2 ? styles.active : '',
                      ].join(' ')}
                      disabled={data.producers.length < 1}
                      onClick={() => {
                        if (activeTab !== 2 && data.producers.length > 0) {
                          setActiveTab(2)
                        }
                      }}
                      as="button"
                    >{`Producenter (${data.producers.length})`}</Box>
                    <Box
                      marginX={[3]}
                      className={[
                        styles.catButton,
                        activeTab === 3 ? styles.active : '',
                      ].join(' ')}
                      disabled={countriesAndRegions.length < 1}
                      onClick={() => {
                        if (activeTab !== 3 && countriesAndRegions.length > 0) {
                          setActiveTab(3)
                        }
                      }}
                      as="button"
                    >{`Länder & regioner (${countriesAndRegions.length})`}</Box>
                  </Flex>
                </Flex>
              </Container>
            </Box>
            {(activeTab === 0 || !gotPosts) && <ProductListing {...data} />}
            {activeTab === 1 && data.articles.length > 0 && (
              <ArticleGrid articles={data.articles} />
            )}
            {activeTab === 2 && data.producers.length > 0 && (
              <ArticleGrid articles={data.producers} />
            )}
            {activeTab === 3 && countriesAndRegions.length > 0 && (
              <ArticleGrid articles={countriesAndRegions} />
            )}
          </Flex>
        )
      }}
    />
  )
}

export default SearchPage
